/* src/pages/Home.css */

* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Inter', Arial, sans-serif; /* Subtle font refinement */
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1; /* Fill the entire main area without setting explicit height */
  width: 100%;
  color: #ffffff;
  text-align: center;
  background: linear-gradient(135deg, #00172d, #004e92, #040b12, #1a1a1a);
  background-size: 200% 200%;
  animation: animatedBackground 20s ease infinite;
  overflow: hidden;
}

/* Simplified Animated Background */
@keyframes animatedBackground {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Neon Title */
.neon-title {
  font-size: 4em;
  font-weight: bold;
  color: #0fffc1;
  text-shadow: 0 0 10px #0fffc1, 0 0 20px #0fffc1, 0 0 30px #0fffc1, 0 0 40px #0fffc1, 0 0 50px #ff00d4, 0 0 60px #ff00d4, 0 0 70px #ff00d4;
}

.neon-subtitle {
  font-size: 1.5em;
  color: #0fffc1;
  text-shadow: 0 0 10px #0fffc1, 0 0 20px #0fffc1, 0 0 30px #ff00d4;
  margin: 10px 0 30px;
}

.neon-button {
  font-size: 1.2em;
  padding: 12px 24px;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.2);
  border: 2px solid #0fffc1;
  border-radius: 5px;
  text-decoration: none;
  box-shadow: 0 0 10px #0fffc1, 0 0 20px #0fffc1, 0 0 30px #ff00d4;
  transition: transform 0.3s ease-in-out;
}

.neon-button:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px #0fffc1, 0 0 30px #ff00d4;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .neon-title {
    font-size: 2.5em;
  }

  .neon-subtitle {
    font-size: 1.2em;
  }

  .neon-button {
    font-size: 1em;
    padding: 10px 20px;
  }

  .home {
    padding: 10px;
    background-size: cover;
  }
}
