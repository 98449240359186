/* src/pages/BLU.css */

/* Global Container */
.blu-overview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    box-sizing: border-box;
    font-family: 'Inter', Arial, sans-serif;
    color: #ffffff;
    background: linear-gradient(135deg, #121212, #1c1c1c);
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.6);
    margin: 20px auto;
    max-width: 1000px;
  }
  
  /* Section Titles */
  h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #ffb74d;
  }
  
  .overview-highlight,
  .features-highlight,
  .slash-commands-highlight,
  .game-sounds-highlight,
  .compatibility-highlight,
  .installation-highlight,
  .support-highlight,
  .feedback-highlight,
  .license-highlight,
  .disclaimer-highlight {
    color: #ffb74d;
  }
  
  /* Paragraphs */
  p {
    font-size: 16px;
    line-height: 1.6;
    color: #cfcfcf;
    margin-bottom: 20px;
  }
  
  /* Strong Highlights */
  .blu-title {
    color: #64b5f6;
    font-size: 18px;
  }
  
  .separator {
    color: #3598db;
  }
  
  /* Features List */
  ul {
    margin: 10px 0;
    padding-left: 20px;
  }
  
  ul li {
    margin-bottom: 8px;
    list-style: none;
    position: relative;
    padding-left: 20px;
  }
  
  ul li::before {
    content: '•';
    color: #00ffea;
    font-size: 18px;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  /* Commands and Notes */
  .command {
    color: #64b5f6;
  }
  
  .note {
    margin-top: 10px;
    font-style: italic;
    color: #ef5350;
  }
  
  .note-highlight {
    color: #ef5350;
    font-weight: bold;
  }
  
  /* Game Sounds Grid */
  .game-sounds {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 10px;
  }
  
  .game-sounds-column {
    flex: 1;
    min-width: 200px;
  }
  
  .game-sounds-column ul {
    list-style: none;
    padding: 0;
  }
  
  .game-sounds-column li {
    margin-bottom: 8px;
  }
  
  /* Installation Steps */
  .installation-step {
    color: #fff;
    font-weight: bold;
  }
  
  .download-link a {
    color: #ffab91;
    text-decoration: none;
  }
  
  .download-link a:hover {
    text-decoration: underline;
  }
  
  /* Compatibility Section */
  .compatibility-item {
    color: #fff;
    margin-bottom: 5px;
  }
  
  /* Support Section */
  .support-method {
    color: #e67e23;
  }
  
  .support-link a {
    color: #ffab91;
    text-decoration: none;
  }
  
  .support-link a:hover {
    text-decoration: underline;
  }
  
  /* Footer Links */
  .feedback-method {
    color: #fff;
  }
  
  .feedback-link a {
    color: #ffab91;
    text-decoration: none;
  }
  
  .feedback-link a:hover {
    text-decoration: underline;
  }
  
  /* License */
  .license-link a {
    color: #ffab91;
    text-decoration: none;
  }
  
  .license-link a:hover {
    text-decoration: underline;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .blu-overview-container {
      padding: 10px;
    }
  
    h2 {
      font-size: 20px;
    }
  
    p {
      font-size: 14px;
    }
  
    ul li {
      font-size: 14px;
    }
  }
  