body, html, #root {
  margin: 0;
  padding: 0;
  font-family: 'Inter', Arial, sans-serif; /* Subtle font refinement */
  background: #0e0e0e;
  color: #ffffff;
  height: 100%;
  overflow: auto; /* Allows scrolling if content exceeds viewport */
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  height: 70px;
  flex-shrink: 0;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centers content if needed */
  padding: 20px;
  box-sizing: border-box;
}
