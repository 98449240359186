/* src/components/NavBar.css */

/* Navbar Base Styles */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(20, 20, 20, 0.9);
  padding: 7px 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

/* Logo */
.logo-container {
  display: flex;
  align-items: center;
  padding-left: 5px;
}

.logo-image {
  height: 20px;
  margin-right: 8px;
}

.logo-text {
  color: #00ffea;
  font-size: 1.2em;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0 0 5px #00ffea, 0 0 10px #00ffea;
}

/* Navigation Links */
.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
}

.nav-links li {
  position: relative;
}

.nav-links a,
.dropdown-toggle {
  color: #ffffff;
  text-decoration: none;
  font-size: 0.9em;
  font-weight: 500;
  padding: 3px 0;
  transition: color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

.nav-links a:hover,
.dropdown-toggle:hover {
  color: #00ffea;
  transform: translateY(-2px);
  text-shadow: 0 0 8px #00ffea, 0 0 12px #00ffea;
}

.nav-links a::after,
.dropdown-toggle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 0;
  height: 2px;
  background-color: #00ffea;
  transition: width 0.4s ease;
}

.nav-links a:hover::after,
.dropdown-toggle:hover::after {
  width: 100%;
}

/* Dropdown Styles */
.dropdown {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(30, 30, 30, 0.95);
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
  z-index: 1001;
  min-width: 220px; /* Adjust width for more content space */
  padding: 10px 15px; /* Increased padding for better spacing */
  overflow: hidden;
}

/* Dropdown Menu Arrow */
.dropdown-menu::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid rgba(30, 30, 30, 0.95);
}

/* Dropdown Menu Items */
.dropdown-menu li {
  margin: 8px 0;
  white-space: nowrap;
}

.dropdown-menu li a {
  color: #ffffff;
  text-decoration: none;
  display: block;
  padding: 6px 10px; /* Added inner padding */
  border-radius: 4px;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.dropdown-menu li:hover {
  background-color: #444;
  color: #00ffea;
}

/* Keep Dropdown Open on Hover */
.dropdown:hover > .dropdown-menu {
  display: block;
}

/* Prevent Hover Gap Issue */
.dropdown::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 10px;
  background: transparent;
  pointer-events: auto;
}

/* Mobile Menu Toggle */
.menu-toggle {
  display: none;
  cursor: pointer;
  color: #00ffea;
  font-size: 1.4em;
  text-shadow: 0 0 5px #00ffea, 0 0 10px #00ffea;
  margin-right: 10px;
}

/* Mobile Navigation */
@media (max-width: 768px) {
  .nav-links {
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 25px;
    background-color: rgba(20, 20, 20, 0.95);
    width: auto;
    padding: 15px;
    display: none;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }

  .nav-links.active {
    display: flex;
  }

  .menu-toggle {
    display: block;
  }

  .dropdown-menu {
    position: static;
    transform: none;
    box-shadow: none;
    background: none;
    border-radius: 0;
    display: none;
  }

  .dropdown:hover .dropdown-menu {
    display: none;
  }

  .dropdown-menu li {
    padding-left: 10px;
  }
}

/* Responsive Dropdown Alignment */
@media (min-width: 769px) and (max-width: 1024px) {
  .dropdown-menu {
    left: 0;
    transform: none;
  }
}
