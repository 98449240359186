/* src/pages/About.css */

/* Global Page Styles */
.about-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  box-sizing: border-box;
  font-family: 'Inter', Arial, sans-serif; /* Subtle font refinement */
}

.about-container {
  max-width: 1000px;
  width: 90%;
  padding: 20px;
  text-align: left;
  color: #ffffff;
  background: linear-gradient(135deg, #121212, #1c1c1c);
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.6);
  margin: 20px 0px;
}

/* Header */
.about-header {
  text-align: center;
  margin-bottom: 0px;
}

.about-title {
  font-size: 36px; /* Converted from em */
  color: #00ffea;
  margin-bottom: 10px;
  text-shadow: 0px 0px 8px rgba(0, 255, 234, 0.5);
}

.about-subtitle {
  font-size: 18px; /* Converted from em */
  color: #cfcfcf;
  margin-bottom: 30px;
  display: block;
}

/* Prevent Overlapping */
.header-spacer {
  margin-top: 20px;
}

/* Section Styling */
.about-section {
  margin: 25px 0px;
  padding: 20px;
  background-color: #1c1c1c;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  animation: fadeIn 0.5s ease-in-out;
}

.about-section:hover {
  transform: translateY(-4px);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.6);
}

/* Section Titles */
.section-title {
  font-size: 24px; /* Converted from em */
  color: #00ffea;
  margin-bottom: 10px;
  text-shadow: 0px 0px 5px rgba(0, 255, 234, 0.3);
}

.section-text {
  font-size: 16px; /* Converted from em */
  color: #cfcfcf;
  line-height: 24px;
  margin-bottom: 10px;
}

/* Bullet Points (Improved Styling) */
.features-list {
  margin: 10px 0px 0px 20px;
  padding-left: 0px;
  list-style-type: none;
}

.features-list li {
  margin-bottom: 8px;
  position: relative;
  padding-left: 20px;
  transition: color 0.2s ease-in-out;
}

.features-list li::before {
  content: '•';
  color: #00ffea;
  font-size: 20px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.features-list li:hover {
  color: #ffffff;
  cursor: pointer;
}

/* Contact Links */
.contact-link {
  color: #00ffea;
  text-decoration: underline;
  transition: color 0.2s ease;
}

.contact-link:hover {
  color: #ffffff;
  text-decoration: none;
}

/* Footer */
.about-footer {
  text-align: center;
  margin-top: 40px;
  padding-top: 10px;
  font-size: 14px;
  border-top: 1px solid #2a2a2a;
  color: #a0a0a0;
}

/* Smooth Scrolling */
html {
  scroll-behavior: smooth;
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* Responsive Adjustments */
@media (max-width: 800px) {
  .about-container {
    padding: 10px;
  }

  .about-title {
    font-size: 28px;
  }

  .about-subtitle {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .section-title {
    font-size: 20px;
  }

  .section-text {
    font-size: 14px;
    line-height: 20px;
  }

  .features-list li {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .about-title {
    font-size: 24px;
  }

  .section-title {
    font-size: 18px;
  }

  .section-text {
    font-size: 14px;
  }

  .features-list li {
    font-size: 13px;
  }
}
