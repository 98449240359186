/* src/components/Footer.css */

.footer {
    width: 100%;
    position: fixed; /* Pins the footer to the bottom */
    bottom: 0;
    background-color: #1a1a1a;
    color: #00ffea;
    text-align: center;
    padding: 10px;
    font-size: 0.6em;
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.3);
    text-shadow: 0 0 5px #00ffea, 0 0 10px #00ffea;
  }
  
  .footer p {
    margin: 0;
    animation: neonGlow 1.5s ease-in-out infinite alternate;
  }
  
  @keyframes neonGlow {
    0% {
      text-shadow: 0 0 5px #00ffea, 0 0 10px #00ffea, 0 0 15px #00ffea;
    }
    100% {
      text-shadow: 0 0 10px #00ffea, 0 0 20px #00ffea, 0 0 30px #00ffea;
    }
  }
  