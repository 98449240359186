/* src/pages/Mods.css */

/* Container for all mod cards */
.mods-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 10px;
  background-color: #0e0e0e;
  color: #ffffff;
  box-sizing: border-box;
}

/* Individual mod card */
.mod-card {
  background-color: #1a1a1a;
  border: 1px solid #333;
  border-radius: 15px;
  margin: 10px;
  width: 800px;
  max-width: 95%;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
  height: 150px; /* Reduced height to tighten up content */
  padding: 8px; /* Reduced padding */
}

@media (min-width: 1200px) {
  .mod-card {
    width: 850px;
  }
}

.mod-card:hover {
  transform: translateY(-6px);
  box-shadow: 0 0 12px #00ffea, 0 0 20px #00ffea, 0 0 28px #00ffea;
}

.mod-image {
  width: 25%;
  height: 100%; /* Matches image height to card height */
  object-fit: cover;
  filter: brightness(85%);
  transition: filter 0.2s ease;
  flex-shrink: 0;
  margin-right: 10px; /* Reduced space between image and text */
}

@media (max-width: 800px) {
  .mod-image {
    width: 100%;
    height: 100px;
    margin-right: 0;
  }
}

.mod-card:hover .mod-image {
  filter: brightness(100%);
}

.mod-content {
  padding: 0; /* Removed padding */
  color: #e0e0e0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Mod title styling */
.mod-title {
  font-size: 1.6em;
  margin: 0 0 4px 0; /* Reduced bottom margin */
  color: #00ffea;
  text-shadow: 0 0 4px #00ffea, 0 0 8px #00ffea;
  font-weight: bold;
  white-space: normal;
}

/* Mod description styling with fixed height */
.mod-description {
  font-size: 1em;
  margin: 2px 0; /* Minimal margin */
  line-height: 1.3;
  color: #bbbbbb;
  max-height: 2.8em; /* Fixed height to ensure consistency across cards */
  overflow: hidden;
  display: -webkit-box;
  display: box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  box-orient: vertical;
}

/* Mod info section with author, version, release date, and download button in-line */
.mod-info {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between elements */
  font-size: 0.8em; /* Adjusted font size for consistency across desktop and mobile */
  color: #aaaaaa;
  line-height: 1.2;
  margin: 0; /* Removed margins */
}

.mod-details {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 1; /* Pushes download button to the far right */
}

.mod-author {
  color: #ff8c00;
}

.mod-version {
  color: #1e90ff;
}

.mod-release-date {
  color: #32cd32;
}

/* Download button styling, in-line with other info elements */
.mod-download-button {
  padding: 4px 10px; /* Reduced padding for compact button */
  background-color: #00ffea;
  color: #000000;
  font-weight: bold;
  text-decoration: none;
  border-radius: 8px;
  text-align: center;
  transition: background-color 0.2s, transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 0 10px #00ffea, 0 0 20px #00ffea, 0 0 30px #00ffea;
  font-size: 0.85em; /* Consistent font size across mobile and desktop */
}

.mod-download-button:hover {
  background-color: #00e6cc;
  transform: translateY(-2px);
  box-shadow: 0 0 15px #00e6cc, 0 0 25px #00e6cc, 0 0 35px #00e6cc;
}

/* Mobile-specific adjustments */
@media (max-width: 800px) {
  .mod-card {
    flex-direction: column;
    width: 90%;
    max-width: 500px;
    padding: 8px; /* Standardized padding */
    height: auto; /* Adjust height for mobile */
  }

  .mod-image {
    width: 100%;
    height: 100px;
  }

  .mod-content {
    padding: 4px 0; /* Compact padding within content for mobile */
  }

  .mod-title {
    font-size: 1.6em;
  }

  .mod-description {
    max-height: 2.8em; /* Fixed height to maintain consistency */
    -webkit-line-clamp: 2; /* Allows for 2 lines */
    line-clamp: 2;
    margin: 2px 0; /* Minimal margin for mobile */
    font-size: 1em;
  }

  .mod-info {
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 0.8em; /* Consistent font size across mobile and desktop */
    margin: 0; /* Removed margins */
  }

  .mod-details,
  .mod-download-button {
    display: inline-flex;
    align-items: center;
  }

  .mod-author,
  .mod-version,
  .mod-release-date {
    font-size: 0.8em; /* Consistent font size across mobile and desktop */
  }

  .mod-download-button {
    font-size: 0.85em;
    padding: 4px 10px;
  }
}
